.c-footer-nav {
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__link {
    color: $color-text-invert;
    font-weight: 700;
  }

  &__item {
    margin-right: $spacing-small;
  }
}
