.c-title {
  color: $color-primary;
  margin-bottom: 0;

  &--secondary {
    color: $color-text;
  }

  &--margin-bottom {
    margin-bottom: $spacing-huge;
  }
}

/* stylelint-disable-next-line selector-no-qualifying-type */
h2.c-title {
  font-size: 2.5rem;
  line-height: 3rem;
}
