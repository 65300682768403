.c-404 {
  padding-top: $spacing-huge;

  &__title {
    color: $color-primary;
    font-size: 10rem;
    line-height: 10rem;
    text-align: center;
  }

  &__text {
    font-size: 1.25rem;
    line-height: 2rem;
    text-align: center;
  }
}
