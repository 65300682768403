/* stylelint-disable prettier/prettier */
.c-icon {
  position: relative;

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &--left {
    padding-left: $spacing-large;

    &::before {
      left: 0;
    }
  }

  &--flex {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      width: 32px;
      height: 32px;
      position: static;
      transform: translateY(0);
      top: none;
      transition: transform $global-transition-time $global-transition-effect;
    }

    &:hover::before {
      transform: translateY(-5px);
    }
  }

  &--facebook::before {
    background: url('/wp-content/themes/naturalskin-chisel/assets/facebook.svg') no-repeat center center;
  }

  &--instagram::before {
    background: url('/wp-content/themes/naturalskin-chisel/assets/instagram.svg') no-repeat center center;
  }

  &--phone::before {
    background: url('/wp-content/themes/naturalskin-chisel/assets/phone.svg') no-repeat center center;
  }

  &--email::before {
    background: url('/wp-content/themes/naturalskin-chisel/assets/email.svg') no-repeat center center;
  }
}
