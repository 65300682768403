.u-margin-bottom-none {
  margin-bottom: 0;
}

.u-margin-bottom-micro {
  margin-bottom: $spacing-micro;
}

.u-font-size--larger {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.u-text--bold {
  font-weight: bold;
}

.u-color--primary {
  color: $color-primary;
}

.u-color--secondary {
  color: $color-secondary;
}

@include bp-down(medium) {
  .admin-bar {
    margin-top: -46px;
  }
}

@include bp(medium) {
  .admin-bar .c-header__wrapper {
    top: 32px;
  }
}

.editor-styles-wrapper .wp-block:not(.wp-block-post-title) {
  max-width: 100%;
  margin: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}
