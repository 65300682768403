/* stylelint-disable prettier/prettier */
.c-contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 0;

  &__details {
    width: 100%;

    @include bp(large) {
      width: 50%;
    }
  }

  &__form-container {
    width: 100%;

    @include bp(large) {
      max-width: 400px;
      margin-left: auto;
      position: relative;

      &::before {
        content: '';
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        width: 200px;
        height: 100%;
        position: absolute;
        top: 0;
        background-image: url('/wp-content/themes/naturalskin-chisel/assets/mailbox.svg');
        right: 120%;
      }
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}
