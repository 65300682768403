@mixin clearButton() {
  color: inherit;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

@mixin lineForLink() {
  content: '';
  background-color: $color-primary;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
  transition: transform $global-transition-time $global-transition-effect;
}

@mixin lineForLinkHover() {
  transform: scaleX(1);
}
