/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  color: $color-text-invert;
  background-color: $color-secondary;

  &__container {
    display: flex;
    flex-wrap: wrap;
  }

  &__address {
    width: 100%;

    @include bp(medium) {
      width: 33.33%;
      padding-right: $spacing-normal;
    }

    @include bp-down(medium) {
      text-align: center;
      margin-bottom: $spacing-large;
    }
  }

  &__links {
    width: 100%;

    @include bp(medium) {
      width: 33.33%;
      padding-right: $spacing-normal;
      padding-left: $spacing-normal;
    }
  }

  &__copyright {
    width: 100%;
    text-align: center;

    @include bp(medium) {
      width: 33.33%;
      padding-left: $spacing-normal;
      text-align: right;
      align-self: flex-end;
    }
  }

  &__logo {
    max-width: 200px;
    margin-bottom: $spacing-large;
    filter: brightness(1000%) grayscale(100%);
  }
}
