.o-link {
  color: inherit;
  text-decoration: none;
  transition: color $global-transition-time $global-transition-effect;

  &:hover,
  &:focus {
    color: $color-primary;
  }
}
