/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  padding: $spacing-tiny 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__wrapper {
    background-color: $color-bg;
    box-shadow: $global-shadow;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }

  &__logo {
    height: 40px;

    @include bp(medium) {
      height: 60px;
    }
  }
}
