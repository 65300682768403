.o-main {
  min-height: 80vh;
  margin-top: 57px;

  @include bp(medium) {
    margin-top: 76px;
  }

  &--padding-bottom {
    padding-bottom: $spacing-great;
  }
}
