/* stylelint-disable selector-no-qualifying-type */
.wpcf7 {
  &-form-control {
    background-color: $color-bg;
  }

  &-form-control:not(input[type='submit']) {
    color: $color-text;
    border: 2px solid $color-border-light;
    border-radius: $global-radius;
    width: 100%;
    max-width: 100%;
    height: $spacing-huge;
    padding: $spacing-tiny $spacing-normal;
    margin-top: $spacing-tiny;

    &:focus {
      border-color: $color-border;
    }

    &:focus-visible {
      outline: none;
    }
  }

  &-textarea {
    min-height: 150px;
  }

  &-response-output {
    border-radius: $global-radius;
    margin: $spacing-big 0 0 0 !important;
    padding: $spacing-tiny $spacing-normal !important;
    border: none !important;
  }

  form.invalid &-response-output {
    background-color: $color-warning !important;
  }

  form.failed &-response-output {
    background-color: $color-error !important;
  }

  form.sent &-response-output {
    background-color: $color-success;
  }
}
