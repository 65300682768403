/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

.c-btn {
  display: inline-block;
  vertical-align: middle;
  font: inherit;
  font-weight: 700;
  text-align: center;
  margin: 0;
  cursor: pointer;
  padding: $spacing-tiny $spacing-normal;
  border-radius: $global-radius;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: background-color 200ms $global-transition-effect;
}

/* Style variants
   ========================================================================== */

.c-btn--primary {
  color: $color-link;
  border: 2px solid $color-link;

  &:focus,
  &:hover {
    color: $color-text-invert;
    background-color: $color-link;
  }
}

.c-btn--secondary {
  color: $color-text-invert;
  border: 2px solid $color-text-invert;

  &:focus,
  &:hover {
    color: $color-text;
    background-color: $color-bg;
  }
}

.c-btn--tertiary {
  color: $color-secondary;
  border: 2px solid $color-secondary;

  &:focus,
  &:hover {
    color: $color-text-invert;
    background-color: $color-secondary;
  }
}

/* Size variants
   ========================================================================== */

.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}

.c-btn--fixed {
  min-width: 200px;
}
