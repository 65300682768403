$baseline: 4px;

$spacing-micro: $baseline * 1;
$spacing-tiny: $baseline * 2;
$spacing-small: $baseline * 3;
$spacing-normal: $baseline * 4;
$spacing-big: $baseline * 5;
$spacing-large: $baseline * 6;
$spacing-great: $baseline * 8;
$spacing-huge: $baseline * 10;
