@charset "UTF-8";
/*
  Project: Naturalskin
  Author: Adam Kanigowski
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
@import-normalize;
/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
dd,
ol,
ul,
figure,
hr,
fieldset,
legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
ol,
ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 20px;
}

/**
 * Consistent indentation for lists.
 */
dd,
ol,
ul {
  margin-left: 16px;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: "“" "”" "‘" "’";
  font-style: italic;
}
blockquote p:first-child::before {
  content: open-quote;
}
blockquote p:last-child::after {
  content: close-quote;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 */
html {
  background: #fff;
  color: #000;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif;
  font-size: 0.8125rem;
  /* 13px */
  line-height: 1.5;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  margin-top: 0 !important;
  scroll-behavior: smooth;
}
@media (min-width: 768px) {
  html {
    font-size: 0.875rem;
    /* 14px */
  }
}
@media (min-width: 1024px) {
  html {
    font-size: 0.9375rem;
    /* 15px */
  }
}
@media (min-width: 1600px) {
  html {
    font-size: 1rem;
    /* 16px */
  }
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
img {
  font-style: italic;
  max-width: 100%;
  vertical-align: middle;
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #9f654f;
  text-decoration: underline;
}
a:hover {
  color: #999;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 8px;
}
li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 16px;
  margin-top: 8px;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 16px;
  margin-left: 16px;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}
@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}
.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

.o-link {
  color: inherit;
  text-decoration: none;
  transition: color 400ms ease-in-out;
}
.o-link:hover, .o-link:focus {
  color: #9f654f;
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

.o-main {
  min-height: 80vh;
  margin-top: 57px;
}
@media (min-width: 768px) {
  .o-main {
    margin-top: 76px;
  }
}
.o-main--padding-bottom {
  padding-bottom: 32px;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}
.o-media::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}
.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}
.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

.o-section {
  padding: 40px 0;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
  table-layout: auto;
  margin: 0 auto;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
  .o-table {
    min-width: 60%;
    max-width: 100%;
    width: auto;
  }
}
.o-table__container {
  overflow-x: auto;
}
.o-table__th {
  background-color: #9f654f;
  color: #fff;
  white-space: nowrap;
  text-align: left;
}
.o-table__th, .o-table__td {
  padding: 12px 20px;
}
.o-table__th:first-child, .o-table__td:first-child {
  min-width: 20rem;
}
.o-table__tr:nth-child(even) {
  background-color: rgba(159, 101, 79, 0.15);
}
.o-table--margin-top {
  margin-top: 32px;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
}
.o-wrapper::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}
@media (max-width: 1023.98px) {
  .o-wrapper {
    padding: 0 12px;
  }
}

.c-404 {
  padding-top: 40px;
}
.c-404__title {
  color: #9f654f;
  font-size: 10rem;
  line-height: 10rem;
  text-align: center;
}
.c-404__text {
  font-size: 1.25rem;
  line-height: 2rem;
  text-align: center;
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
.c-btn {
  display: inline-block;
  vertical-align: middle;
  font: inherit;
  font-weight: 700;
  text-align: center;
  margin: 0;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 8px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: background-color 200ms ease-in-out;
}

/* Style variants
   ========================================================================== */
.c-btn--primary {
  color: #9f654f;
  border: 2px solid #9f654f;
}
.c-btn--primary:focus, .c-btn--primary:hover {
  color: #fff;
  background-color: #9f654f;
}

.c-btn--secondary {
  color: #fff;
  border: 2px solid #fff;
}
.c-btn--secondary:focus, .c-btn--secondary:hover {
  color: #000;
  background-color: #fff;
}

.c-btn--tertiary {
  color: #365266;
  border: 2px solid #365266;
}
.c-btn--tertiary:focus, .c-btn--tertiary:hover {
  color: #fff;
  background-color: #365266;
}

/* Size variants
   ========================================================================== */
.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}

.c-btn--fixed {
  min-width: 200px;
}

.c-card {
  padding: 24px;
}
.c-card--big {
  padding: 40px;
}
.c-card--shadow {
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
}

/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */
.c-comment-form {
  margin-top: 2em;
}
.c-comment-form label {
  display: block;
}
.c-comment-form textarea {
  max-width: 100%;
}

.comment-reply-title {
  margin-bottom: 0.25em;
}

/* ==========================================================================
   #COMMENT
   ========================================================================== */
.c-comment {
  margin-bottom: 1em;
}

.c-comment__author {
  font-size: 1rem;
  margin-bottom: 0.25em;
}

.c-comment__moderation {
  font-style: italic;
  margin-bottom: 0.5em;
}

.c-cookies {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
  display: none;
}
@media (max-width: 767.98px) {
  .c-cookies {
    padding: 0 12px;
  }
}
.c-cookies.is-active {
  display: block;
}
.c-cookies__container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 32px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767.98px) {
  .c-cookies__container {
    flex-direction: column;
    padding: 16px;
  }
}
.c-cookies__text {
  width: 100%;
}
@media (min-width: 768px) {
  .c-cookies__text {
    margin-right: 40px;
  }
}
.c-cookies__btn {
  margin-top: 12px;
}
@media (min-width: 768px) {
  .c-cookies__btn {
    margin: 0 0 0 auto;
  }
}

.c-footer-nav__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.c-footer-nav__link {
  color: #fff;
  font-weight: 700;
}
.c-footer-nav__item {
  margin-right: 12px;
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  color: #fff;
  background-color: #365266;
}
.c-footer__container {
  display: flex;
  flex-wrap: wrap;
}
.c-footer__address {
  width: 100%;
}
@media (min-width: 768px) {
  .c-footer__address {
    width: 33.33%;
    padding-right: 16px;
  }
}
@media (max-width: 767.98px) {
  .c-footer__address {
    text-align: center;
    margin-bottom: 24px;
  }
}
.c-footer__links {
  width: 100%;
}
@media (min-width: 768px) {
  .c-footer__links {
    width: 33.33%;
    padding-right: 16px;
    padding-left: 16px;
  }
}
.c-footer__copyright {
  width: 100%;
  text-align: center;
}
@media (min-width: 768px) {
  .c-footer__copyright {
    width: 33.33%;
    padding-left: 16px;
    text-align: right;
    align-self: flex-end;
  }
}
.c-footer__logo {
  max-width: 200px;
  margin-bottom: 24px;
  filter: brightness(1000%) grayscale(100%);
}

.c-hamburger {
  color: inherit;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  width: 24px;
  height: 18px;
  position: relative;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .c-hamburger {
    display: none;
  }
}
.c-hamburger__line {
  background-color: #000;
  border-radius: 8px;
  width: 24px;
  height: 2px;
  position: relative;
  transition: background-color 300ms ease-in-out;
  /* stylelint-disable-next-line prettier/prettier */
}
.c-hamburger__line::before, .c-hamburger__line::after {
  content: "";
  background-color: #000;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  transition: transform 400ms ease-in-out;
}
.c-hamburger__line::before {
  top: -6px;
}
.c-hamburger__line::after {
  bottom: -6px;
}
.c-hamburger.is-active .c-hamburger__line {
  background-color: #fff;
}
.c-hamburger.is-active .c-hamburger__line::before {
  transform: translateY(6px) rotate(45deg);
}
.c-hamburger.is-active .c-hamburger__line::after {
  transform: translateY(-6px) rotate(-45deg);
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.c-header__wrapper {
  background-color: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
.c-header__logo {
  height: 40px;
}
@media (min-width: 768px) {
  .c-header__logo {
    height: 60px;
  }
}

.c-hero {
  height: calc(100vh - 56px);
  position: relative;
}
@media (min-width: 768px) {
  .c-hero {
    height: calc(100vh - 76px);
  }
}
.c-hero__wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.c-hero .o-wrapper {
  width: 100%;
  height: 100%;
}
.c-hero__image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
.c-hero__image::after {
  content: "";
  background-color: #9f654f;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.6;
}
.c-hero__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .c-hero__content {
    max-width: 50%;
    margin: 0 auto;
  }
}
@media (min-width: 1024px) {
  .c-hero__content {
    margin: 0;
    padding: 0 32px 0 0;
  }
}
.c-hero__title {
  color: #fff;
  font-size: 2.5rem;
}
.c-hero__description {
  color: #fff;
}
.c-hero__description p {
  font-size: 1.25rem;
  line-height: 2.25rem;
}

/* stylelint-disable prettier/prettier */
.c-icon {
  position: relative;
}
.c-icon::before {
  content: "";
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.c-icon--left {
  padding-left: 24px;
}
.c-icon--left::before {
  left: 0;
}
.c-icon--flex {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-icon--flex::before {
  width: 32px;
  height: 32px;
  position: static;
  transform: translateY(0);
  top: none;
  transition: transform 400ms ease-in-out;
}
.c-icon--flex:hover::before {
  transform: translateY(-5px);
}
.c-icon--facebook::before {
  background: url("/wp-content/themes/naturalskin-chisel/assets/facebook.svg") no-repeat center center;
}
.c-icon--instagram::before {
  background: url("/wp-content/themes/naturalskin-chisel/assets/instagram.svg") no-repeat center center;
}
.c-icon--phone::before {
  background: url("/wp-content/themes/naturalskin-chisel/assets/phone.svg") no-repeat center center;
}
.c-icon--email::before {
  background: url("/wp-content/themes/naturalskin-chisel/assets/email.svg") no-repeat center center;
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
.c-main-nav {
  background-color: #fff;
  display: flex;
}
@media (max-width: 767.98px) {
  .c-main-nav {
    flex-direction: column;
    width: 100%;
    padding: 20px 12px;
    position: absolute;
    left: 0;
    top: 57px;
    transform: translateX(-110%);
    transition: transform 400ms ease-in-out;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  }
  .c-main-nav.is-active {
    transform: translateX(0);
  }
}
.c-main-nav__list {
  display: flex;
  margin: 0 0 0 20px;
}
@media (max-width: 767.98px) {
  .c-main-nav__list {
    flex-direction: column;
    justify-content: center;
    margin: 0;
  }
}
.c-main-nav__item {
  font-weight: bold;
  list-style: none;
  margin: 0 16px 0 0;
  padding: 0;
}
@media (max-width: 767.98px) {
  .c-main-nav__item:last-child {
    margin-bottom: 12px;
  }
}
.c-main-nav__link {
  color: #000;
  display: block;
  padding: 8px;
  text-decoration: none;
  text-align: center;
  position: relative;
}
.c-main-nav__link:hover, .c-main-nav__link:focus {
  color: #365266;
}
.c-main-nav__link::before {
  content: "";
  background-color: #9f654f;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
  transition: transform 400ms ease-in-out;
}
.c-main-nav__link:hover::before, .c-main-nav__link:focus::before {
  transform: scaleX(1);
}
.current-menu-item .c-main-nav__link {
  color: #9f654f;
}
.current-menu-item .c-main-nav__link::before {
  transform: scaleX(1);
}
.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
}
.c-main-nav__item:hover .c-main-nav__dropdown {
  display: flex;
}
.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}
.c-main-nav__sublink {
  background: #fff;
  border-bottom: 1px solid #9f654f;
  display: block;
  padding: 0.5em;
  text-decoration: none;
}
.current-menu-item .c-main-nav__sublink {
  border-bottom-color: #000;
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post {
  margin-bottom: 2em;
}
.c-post::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

.c-social-icons {
  display: flex;
  position: absolute;
  margin: 0;
}
@media (min-width: 768px) {
  .c-social-icons {
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
    top: 0;
    right: 0;
  }
}
@media (max-width: 767.98px) {
  .c-social-icons {
    width: 100vw;
    top: 100%;
    left: 0;
  }
}
.c-social-icons__item {
  list-style: none;
  margin: 0;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
}
@media (max-width: 767.98px) {
  .c-social-icons__item {
    width: 25%;
  }
}
.c-social-icons__link {
  background-color: #9f654f;
  width: 64px;
  height: 64px;
}
@media (max-width: 767.98px) {
  .c-social-icons__link {
    width: 100%;
  }
}

.c-title {
  color: #9f654f;
  margin-bottom: 0;
}
.c-title--secondary {
  color: #000;
}
.c-title--margin-bottom {
  margin-bottom: 40px;
}

/* stylelint-disable-next-line selector-no-qualifying-type */
h2.c-title {
  font-size: 2.5rem;
  line-height: 3rem;
}

/* stylelint-disable selector-no-qualifying-type */
.wpcf7-form-control {
  background-color: #fff;
}
.wpcf7-form-control:not(input[type=submit]) {
  color: #000;
  border: 2px solid #ccc;
  border-radius: 8px;
  width: 100%;
  max-width: 100%;
  height: 40px;
  padding: 8px 16px;
  margin-top: 8px;
}
.wpcf7-form-control:not(input[type=submit]):focus {
  border-color: #9f654f;
}
.wpcf7-form-control:not(input[type=submit]):focus-visible {
  outline: none;
}
.wpcf7-textarea {
  min-height: 150px;
}
.wpcf7-response-output {
  border-radius: 8px;
  margin: 20px 0 0 0 !important;
  padding: 8px 16px !important;
  border: none !important;
}
form.invalid .wpcf7-response-output {
  background-color: #f9f494 !important;
}
form.failed .wpcf7-response-output {
  background-color: #eeb0b0 !important;
}
form.sent .wpcf7-response-output {
  background-color: #a9f38c;
}

.c-columns__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.c-columns__content {
  width: 100%;
}
@media (min-width: 768px) {
  .c-columns__content {
    width: 50%;
    padding: 0 0 0 32px;
  }
}
.c-columns__image {
  border-radius: 8px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  max-width: 100% !important;
  height: auto;
}
.c-columns__image-container {
  width: 100%;
  padding-bottom: 32px;
}
@media (min-width: 768px) {
  .c-columns__image-container {
    width: 50%;
    padding: 0 32px 0 0;
  }
}
@media (min-width: 768px) {
  .c-columns__container--align-revert .c-columns__content {
    padding: 0 32px 0 0;
    order: 1;
  }
  .c-columns__container--align-revert .c-columns__image-container {
    order: 2;
    padding: 0 0 0 32px;
  }
}
.c-columns__title {
  color: #fff;
  font-size: 2rem;
  line-height: 2.5rem;
}
.c-columns__description {
  color: #fff;
}
.c-columns__description p {
  font-size: 1.25rem;
  line-height: 2rem;
}

/* stylelint-disable prettier/prettier */
.c-contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 0;
}
.c-contact__details {
  width: 100%;
}
@media (min-width: 1024px) {
  .c-contact__details {
    width: 50%;
  }
}
.c-contact__form-container {
  width: 100%;
}
@media (min-width: 1024px) {
  .c-contact__form-container {
    max-width: 400px;
    margin-left: auto;
    position: relative;
  }
  .c-contact__form-container::before {
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 200px;
    height: 100%;
    position: absolute;
    top: 0;
    background-image: url("/wp-content/themes/naturalskin-chisel/assets/mailbox.svg");
    right: 120%;
  }
}
.c-contact__form-container p:last-child {
  margin-bottom: 0;
}

/* stylelint-disable max-nesting-depth */
.c-employees__item {
  padding: 40px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .c-employees__item:nth-child(even) {
    flex-direction: row-reverse;
  }
  .c-employees__item:nth-child(even) .c-employees__image {
    margin-left: auto;
  }
  .c-employees__item:nth-child(even) .c-employees__image-container {
    padding: 0 0 0 40px;
  }
}
.c-employees__image {
  max-width: 100% !important;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  display: block;
}
@media (max-width: 767.98px) {
  .c-employees__image {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.c-employees__image-container {
  width: 100%;
}
@media (min-width: 768px) {
  .c-employees__image-container {
    width: 33.33%;
    padding-right: 40px;
  }
}
.c-employees__content {
  width: 100%;
  background-color: rgba(159, 101, 79, 0.15);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
}
@media (max-width: 767.98px) {
  .c-employees__content {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
@media (min-width: 768px) {
  .c-employees__content {
    width: 66.66%;
    padding: 40px;
  }
}
.c-employees__title {
  margin: 20px 0;
  font-size: 2rem;
  line-height: 2.5rem;
}
@media (min-width: 768px) {
  .c-employees__title {
    margin: 0 0 20px;
  }
}
.c-employees__description {
  font-size: 1.25rem;
  line-height: 2rem;
}
.c-employees__description p:last-child {
  margin-bottom: 0;
}
.c-employees__job-titles {
  background-color: #9f654f;
  color: #fff;
  border-radius: 8px;
  font-size: 1.25rem;
  line-height: 1.45rem;
  font-weight: 700;
  padding: 12px 32px;
  margin-bottom: 20px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  display: inline-block;
}

.c-icons__container {
  display: flex;
  justify-content: space-evenly;
}
@media (max-width: 767.98px) {
  .c-icons__container {
    flex-direction: column;
    align-items: center;
  }
}
.c-icons__item {
  padding: 20px 32px;
}
.c-icons__image {
  max-width: 200px;
  height: 100px;
  display: block;
  margin: 0 auto;
}
.c-icons__text {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
  margin-top: 24px;
  text-align: center;
}

.c-map {
  width: 100%;
  padding: 0;
  background-color: #fff;
}
.c-map iframe {
  width: 100% !important;
  overflow: hidden;
  margin-bottom: -16px;
}

.c-offer {
  background-color: #fff;
}
.c-offer__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.c-offer__item-container {
  width: 100%;
  margin-top: 125px;
  margin-bottom: 60px;
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .c-offer__item-container {
    width: 50%;
  }
  .c-offer__item-container:nth-child(even) {
    padding-left: 16px;
  }
  .c-offer__item-container:nth-child(odd) {
    padding-right: 16px;
  }
}
@media (min-width: 1024px) {
  .c-offer__item-container {
    width: 33.33%;
  }
  .c-offer__item-container:nth-child(3n+1) {
    padding-right: 16px;
  }
  .c-offer__item-container:nth-child(3n+2) {
    padding: 0 16px;
  }
  .c-offer__item-container:nth-child(3n+3) {
    padding-left: 16px;
  }
}
.c-offer__item {
  background-color: rgba(159, 101, 79, 0.15);
  border-radius: 8px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  height: 100%;
  padding: 125px 20px 20px;
  position: relative;
}
.c-offer__image-container {
  border-radius: 100%;
  width: 250px;
  height: 250px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}
.c-offer__image-container::after {
  content: "";
  border: 6px solid rgba(159, 101, 79, 0.15);
  border-radius: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.c-offer__image {
  border-radius: 100%;
  width: 250px;
  height: 250px;
}
.c-offer__title {
  color: #9f654f;
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
}
.c-offer--col-2 .c-offer__item-container {
  width: 100%;
  margin-top: 125px;
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  .c-offer--col-2 .c-offer__item-container {
    width: 50%;
  }
  .c-offer--col-2 .c-offer__item-container:nth-child(even) {
    padding-left: 16px;
    padding-right: 0;
  }
  .c-offer--col-2 .c-offer__item-container:nth-child(odd) {
    padding-right: 16px;
  }
}
.c-offer__btn {
  display: flex;
  justify-content: center;
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-text--center {
  text-align: center;
}
.u-text--left {
  text-align: left;
}
.u-text--right {
  text-align: right;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.u-margin-bottom-none {
  margin-bottom: 0;
}

.u-margin-bottom-micro {
  margin-bottom: 4px;
}

.u-font-size--larger {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.u-text--bold {
  font-weight: bold;
}

.u-color--primary {
  color: #9f654f;
}

.u-color--secondary {
  color: #365266;
}

@media (max-width: 767.98px) {
  .admin-bar {
    margin-top: -46px;
  }
}
@media (min-width: 768px) {
  .admin-bar .c-header__wrapper {
    top: 32px;
  }
}
.editor-styles-wrapper .wp-block:not(.wp-block-post-title) {
  max-width: 100%;
  margin: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}
@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}