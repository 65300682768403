/* ==========================================================================
   #IMAGES
   ========================================================================== */

img {
  font-style: italic;
  max-width: 100%;
  vertical-align: middle;
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */

img[width],
img[height] {
  max-width: none;
}
