// ==========================================================================
// #GLOBAL
// ==========================================================================
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-radius: 8px !default;
$global-transition-time: 400ms !default;
$global-transition-effect: ease-in-out !default;
$global-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);

/* Fonts
   ========================================================================== */

$font-heading: 'Merriweather', serif;
$font-sans-serif: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
  'Roboto', 'Helvetica Neue', 'Arial', sans-serif !default;

/* Breakpoints
   ========================================================================== */

$breakpoints: (
  small: 480px,
  medium: 768px,
  large: 1024px,
  xlarge: 1280px,
  full: 1600px,
);

/* Colors
   ========================================================================== */

// Colors shouldn't be used directly but reassigned
$color-primary: #9f654f;
$color-primary-light: transparentize($color-primary, 0.85);
$color-secondary: #365266;
$color-white: #fff;
$color-black: #000;
$color-very-light-grey: #ccc;
$color-light-grey: #999;
$color-medium-grey: #666;
$color-dark-grey: #333;
$color-success: #a9f38c;
$color-error: #eeb0b0;
$color-warning: #f9f494;

// Text
$color-bg: $color-white;
$color-text: $color-black;
$color-text-invert: $color-white;
$color-icon: $color-black;

// Links
$color-link: $color-primary;
$color-hover: $color-light-grey;

// Borders
$color-border: $color-primary;
$color-border-light: $color-very-light-grey;

// Fills
$color-fill: $color-very-light-grey;
