.c-hero {
  height: calc(100vh - 56px);
  position: relative;

  @include bp(medium) {
    height: calc(100vh - 76px);
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .o-wrapper {
    width: 100%;
    height: 100%;
  }

  &__image {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;

    &::after {
      content: '';
      background-color: $color-primary;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.6;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @include bp(medium) {
      max-width: 50%;
      margin: 0 auto;
    }

    @include bp(large) {
      margin: 0;
      padding: 0 $spacing-great 0 0;
    }
  }

  &__title {
    color: $color-text-invert;
    font-size: 2.5rem;
  }

  &__description {
    color: $color-text-invert;

    p {
      font-size: 1.25rem;
      line-height: 2.25rem;
    }
  }
}
