/* stylelint-disable max-nesting-depth */
.c-employees {
  $self: &;

  &__item {
    padding: $spacing-huge 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    &:nth-child(even) {
      @include bp(medium) {
        flex-direction: row-reverse;

        #{$self}__image {
          margin-left: auto;
        }

        #{$self}__image-container {
          padding: 0 0 0 $spacing-huge;
        }
      }
    }
  }

  &__image {
    max-width: 100% !important;
    height: auto;
    border-radius: $global-radius;
    box-shadow: $global-shadow;
    display: block;

    @include bp-down(medium) {
      width: 100%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &-container {
      width: 100%;

      @include bp(medium) {
        width: 33.33%;
        padding-right: $spacing-huge;
      }
    }
  }

  &__content {
    width: 100%;
    background-color: $color-primary-light;
    padding: $spacing-big;
    border-radius: $global-radius;
    box-shadow: $global-shadow;

    @include bp-down(medium) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    @include bp(medium) {
      width: 66.66%;
      padding: $spacing-huge;
    }
  }

  &__title {
    margin: $spacing-big 0;
    font-size: 2rem;
    line-height: 2.5rem;

    @include bp(medium) {
      margin: 0 0 $spacing-big;
    }
  }

  &__description {
    font-size: 1.25rem;
    line-height: 2rem;

    p:last-child {
      margin-bottom: 0;
    }
  }

  &__job-titles {
    background-color: $color-primary;
    color: $color-text-invert;
    border-radius: $global-radius;
    font-size: 1.25rem;
    line-height: 1.45rem;
    font-weight: 700;
    padding: $spacing-small $spacing-great;
    margin-bottom: $spacing-big;
    box-shadow: $global-shadow;
    display: inline-block;
  }
}
