.c-columns {
  $self: &;

  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__content {
    width: 100%;

    @include bp(medium) {
      width: 50%;
      padding: 0 0 0 $spacing-great;
    }
  }

  &__image {
    border-radius: $global-radius;
    box-shadow: $global-shadow;
    max-width: 100% !important;
    height: auto;

    &-container {
      width: 100%;
      padding-bottom: $spacing-great;

      @include bp(medium) {
        width: 50%;
        padding: 0 $spacing-great 0 0;
      }
    }
  }

  &__container--align-revert {
    @include bp(medium) {
      #{$self}__content {
        padding: 0 $spacing-great 0 0;
        order: 1;
      }

      #{$self}__image-container {
        order: 2;
        padding: 0 0 0 $spacing-great;
      }
    }
  }

  &__title {
    color: $color-text-invert;
    font-size: 2rem;
    line-height: 2.5rem;
  }

  &__description {
    color: $color-text-invert;

    p {
      font-size: 1.25rem;
      line-height: 2rem;
    }
  }
}
