.c-offer {
  background-color: $color-bg;

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item-container {
    width: 100%;
    margin-top: 125px;
    margin-bottom: $spacing-huge + $spacing-big;

    @include bp-between(medium, large) {
      width: 50%;

      &:nth-child(even) {
        padding-left: $spacing-normal;
      }

      &:nth-child(odd) {
        padding-right: $spacing-normal;
      }
    }

    @include bp(large) {
      width: 33.33%;

      &:nth-child(3n + 1) {
        padding-right: $spacing-normal;
      }

      &:nth-child(3n + 2) {
        padding: 0 $spacing-normal;
      }

      &:nth-child(3n + 3) {
        padding-left: $spacing-normal;
      }
    }
  }

  &__item {
    background-color: $color-primary-light;
    border-radius: $global-radius;
    box-shadow: $global-shadow;
    height: 100%;
    padding: 125px $spacing-big $spacing-big;
    position: relative;
  }

  &__image-container {
    border-radius: 100%;
    width: 250px;
    height: 250px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;

    &::after {
      content: '';
      border: 6px solid $color-primary-light;
      border-radius: 100%;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__image {
    border-radius: 100%;
    width: 250px;
    height: 250px;
  }

  &__title {
    color: $color-primary;
    text-align: center;
    text-transform: uppercase;
    margin-top: $spacing-big;
  }

  &--col-2 &__item-container {
    width: 100%;
    margin-top: 125px;
    margin-bottom: $spacing-great;

    @include bp(medium) {
      width: 50%;

      &:nth-child(even) {
        padding-left: $spacing-normal;
        padding-right: 0;
      }

      &:nth-child(odd) {
        padding-right: $spacing-normal;
      }
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
  }
}
