.c-card {
  padding: $spacing-large;

  &--big {
    padding: $spacing-huge;
  }

  &--shadow {
    box-shadow: $global-shadow;
  }
}
